import React from 'react';
import Head from "../components/Head";
import Layout from "../components/Layout";
import Link from "gatsby-link";


const Impressum = () => {
    return (
        <Layout newsletter={false}>
            <Head title={"Impressum"}/>
            <div className="page__outer">
                <div className="page__inner">
                    <h3>Impressum</h3>
                    <p className="color-dark large">
                        <strong>Kulturverein 21</strong> <br/>
                        Schönbrunnerstrasse 3 <br/>
                        1040 Wien <br/>
                        <span className="email">office@marktderzukunft.at</span>    <br/><br/>
                        <p>
                            Registriert bei der Landespolizeidirektion Wien, Referat Vereins, Versammlungs- und Medienrechtsangelegenheiten unter der ZVR-Zahl: 606353307
                        </p>
                    </p><br/>
                    <div className="impressum__credit__container">
                        <p>
                            Webdesign und Webdevelopment von
                        </p>
                        <a href={"https://studio-plus.plus/"} className="hover-moveup studiolink">
                            <svg xmlns="http://www.w3.org/2000/svg" width="155.31" height="31.201" viewBox="0 0 155.31 31.201">
                                <g className={"svg-fill"} id="Group_144" data-name="Group 144" transform="translate(-22.026 -14.088)">
                                    <path id="Path_236" data-name="Path 236" d="M37.412,69.555v4.2L33.426,77.74h-7.8l-3.6-3.643,2.657-2.615L27,73.8h4.714l1.457-1.414v-1.5l-1.457-1.414H26.183L22.2,65.483V61.24l3.986-3.986h7.543L37.5,60.983,34.841,63.64l-2.486-2.486H27.94L26.4,62.7v1.329l1.543,1.543h5.486Z" transform="translate(0 -32.451)"/>
                                    <path id="Path_237" data-name="Path 237" d="M108.82,61.774h-6L98.4,57.36V45.188H95.919v-3.9H98.4V36.016h4.372v5.272h6.043v3.9h-6.043V55.3l2.529,2.529h3.514Z" transform="translate(-55.551 -16.485)"/>
                                    <path id="Path_238" data-name="Path 238" d="M172.991,75.212l-2.529,2.529H165.49l-4.543-4.586v-12h-2.7v-3.9h7.072V71.312l2.143,2.143h2.914l2.443-2.486V61.754l-1.757-.6h-2.143v-3.9h5.571l2.7,2.7V73.24l1.457.6h1.457v3.9h-4.629Z" transform="translate(-102.408 -32.451)"/>
                                    <path id="Path_239" data-name="Path 239" d="M272.324,42.632l-2.657,2.657H264.31L259.767,40.7V29.346L264.31,24.8h5.657l2.314,2.314V18.631l-1.457-.6h-1.457V14.088H274l2.657,2.7V40.746l1.457.6h1.457v3.943h-4.586ZM269.839,41l2.443-2.443V31.531l-2.443-2.443h-3.6l-2.1,2.1V38.86l2.1,2.143Z" transform="translate(-178.729)"/>
                                    <path id="Path_240" data-name="Path 240" d="M351.1,45.809V41.866h2.743V29.822l-1.457-.6h-1.457v-3.9h4.586l2.7,2.7V41.866H361v3.943Zm4.714-31.03,3.3,3.3-3.343,3.343-3.3-3.3Z" transform="translate(-247.26 -0.519)"/>
                                    <path id="Path_241" data-name="Path 241" d="M405.311,73.155V61.8l4.586-4.543h7.8L422.24,61.8V73.155L417.7,77.74h-7.8Zm10.458.386,2.1-2.1V63.554l-2.1-2.1h-3.943l-2.1,2.1V71.44l2.1,2.1Z" transform="translate(-288.146 -32.451)"/>
                                    <path id="Path_242" data-name="Path 242" d="M497.287,76.6h-6.858V73h6.858V65.542h3.9V73h6.857v3.6h-6.857v7.372h-3.9Z" transform="translate(-352.136 -38.682)"/>
                                    <path id="Path_243" data-name="Path 243" d="M583.613,76.6h-6.857V73h6.857V65.542h3.9V73h6.857v3.6h-6.857v7.372h-3.9Z" transform="translate(-417.035 -38.682)"/>
                                </g>
                            </svg>

                        </a>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default Impressum;
