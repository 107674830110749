import React from 'react';
import Head from "../components/Head";
import Layout from "../components/Layout";
import Link from "gatsby-link";


const Datenschutz = () => {
    return (
        <Layout newsletter={false}>
            <Head title={"Datenschutz"}/>
            <div className="page__outer">
                <div className="page__inner">
                    <h3 className={"datenschutz"}>Datenschutz</h3>
                    <p className="color-dark">
                        <strong>1.) Name und Kontaktdaten des für die Datenverarbeitung Verantwortlichen:</strong>
                        <br/>
                        Diese Datenschutzhinweise gelten für die Datenverarbeitung durch: <br/>
                        Kulturverein 21 (im Folgenden: MDZ) <br/>
                        Schönbrunnerstrasse 3, 1040 Wien <br/>
                        E-Mail: office@marktderzukunft.at
                        <br/><br/>
                        <strong>2.) Weitergabe von Daten an Dritte</strong>
                        <br/>
                        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden
                        aufgeführten Zwecken findet nicht statt.
                        <br/>
                        <br/>
                            <strong>3.) Betroffenenrechte</strong>
                        <br/>
                        Sie haben das Recht:
                        <br/>· gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu
                        widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung
                        beruht, für die Zukunft nicht mehr fortführen dürfen;
                        <br/>· gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu
                        verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der
                        personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
                        wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
                        Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
                        die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen
                        einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
                        Informationen zu deren Einzelheiten verlangen;
                        <br/>· gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung
                        Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
                        <br/>· gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu
                        verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungs-äußerung und
                        Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen
                        Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
                        ist;
                        <br/>· gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                        verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung
                        unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie
                        jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder
                        Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                        <br/>· gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in
                        einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung
                        an einen anderen Verantwortlichen zu verlangen und
                        <br/>· gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.
                        <br/><br/>
                                <strong>4.) Widerspruchsrecht</strong>
                        <br/>
                        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs.
                        1 S. 1 lit. f) DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch
                        gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen,
                        die sich aus Ihrer besonderen Situation ergeben.
                        Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an
                        office@marktderzukunft.at
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default Datenschutz;
