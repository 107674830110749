import React, {useEffect, useRef, useState} from 'react';
import Link from "gatsby-link";

import addToMailchimp from "gatsby-plugin-mailchimp";


const LandingNewsletter = ({}) => {
    function handleChange(e) {
        value[e.target.id] = e.target.value;
        setValue({...value})
        document.querySelector('#emaillanding').style.borderColor = 'var(--dark-color)';
    }

    const [value, setValue] = React.useState({})


    async function onSubmit(e) {
        e.preventDefault()
        if (value.emaillanding) {
            console.log('Adding to Newsletter soon..');
            addToMailchimp(value.emaillanding)
                .then(data => {
                    console.log('Mailchimp Success:')
                    document.querySelector('#form').style.display = 'none';
                    document.querySelector('#successmessage').style.display = "block"
                })
                .catch(() => {
                    console.error('Error adding to Mailchimp')
                })
        } else {
            document.querySelector('#emaillanding').style.borderColor = '#F05353';
        }
    }

    return (
        <>
            <p className={"large"} id="successmessage">Hinzugefügt!</p>
            <form onSubmit={onSubmit} id={"form"}>
                <input className={"inputfield regular fixedwidth"} autoComplete="off" placeholder={"enter@email.here"} type="email" name="EMAIL" id={"emaillanding"} value={value[`emaillanding`] || ``} onChange={handleChange}/>
                <div className="btn__outer">
                    <button type={"submit"} className="btn__inner btn__arrowdown lowercase input text-center color-light border-dark bg-dark hover-moveup">
                        Newsletter Abonnieren
                        <svg xmlns="http://www.w3.org/2000/svg" style={{transform: "rotate(-90deg) scale(var(--scale))"}} width="11.863" height="13.984" viewBox="0 0 11.863 13.984">
                            <g id="Group_144" className={"svg-path-light svg-path"} data-name="Group 144" transform="translate(-1067.314 -667.193)">
                                <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(1926.027 2)"/>
                                <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(1926.459 0.21)"/>
                            </g>
                        </svg>
                    </button>
                    <p className={"input"}>
                        Wir verarbeiten Daten
                        gemäß <Link to={"/datenschutz"} className={"underline color-dark"}>Datenschutzbestimmungen</Link>.
                    </p>
                </div>
            </form>
        </>
    )
}

export default LandingNewsletter;
