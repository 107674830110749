import * as React from "react"
import Layout from "../components/Layout";
import Head from "../components/Head";
import NavigationNewsletter from "../components/NavigationNewsletter";
import NavigationMarquee from "../components/NavigationMarquee";
import {StaticImage} from "gatsby-plugin-image";
import {useRef, useState} from "react";
import Marquee from "react-fast-marquee";
import Link from "gatsby-link";
import LandingNewsletter from "../components/LandingNewsletter";
import SponsorMarquee from "../components/SponsorMarquee";
import Footer from "../components/Footer";

const IndexPage = () => {

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const contentRef = useRef();

    return (
        <Layout>
            <Head/>
            <div className="landing__outer">
                <div className="landing__inner">
                    <div className="landing__logo">
                        <svg id="Group_34" data-name="Group 34" xmlns="http://www.w3.org/2000/svg" width="1157.931" height="370.099" viewBox="0 0 1157.931 370.099">
                            <g className={"svg-fill-dark"}>
                                <path id="Path_38" data-name="Path 38" d="M104.221,29.629h32.357L189,99.3,240.12,29.629h32.357V190.335H239.042V86.792l-48.536,62.557h-4.313L137.656,84.42V190.335H104.221Z" transform="translate(27.564 -27.04)"/>
                                <path id="Path_39" data-name="Path 39" d="M236.569,146.551c0-19.845,13.591-33.22,41.849-37.75l32.573-5.177v-3.236c0-8.843-7.55-15.746-19.629-15.746A25.958,25.958,0,0,0,266.553,101.9L240.668,89.6c6.9-19.629,27.18-31.925,52.2-31.925,29.77,0,50.478,17.472,50.478,42.71v77.657h-30.2v-11c-9.276,9.275-20.494,13.589-35.377,13.589C252.317,180.634,236.569,167.906,236.569,146.551Zm47.673,10.355c15.962,0,26.748-11.433,26.748-25.885v-3.669L284.242,132.1C274.966,133.824,270,137.707,270,145.473,270,152.807,276.045,156.906,284.242,156.906Z" transform="translate(85.552 -14.751)"/>
                                <path id="Path_40" data-name="Path 40" d="M329.818,59.873h30.2V77.561c6.039-14.669,17.472-18.984,31.924-18.984h6.9V86.619H388.709c-15.748,0-26.534,10.139-26.534,27.612v63.419H329.818Z" transform="translate(126.408 -14.357)"/>
                                <path id="Path_41" data-name="Path 41" d="M389.216,27.829h32.357v88.012l39.907-42.5h39.907L458.676,120.8l42.5,70.323H464.5l-29.984-50.26L421.573,155.1v36.024H389.216Z" transform="translate(152.433 -27.829)"/>
                                <path id="Path_42" data-name="Path 42" d="M491.912,142.91V95.67H472.066V67.628h1.079c12.08,0,18.768-6.042,18.768-18.121V40.878h32.357v26.75h27.612V95.67H524.269v45.083c0,12.08,6.47,17.9,20.062,17.9a56.093,56.093,0,0,0,7.55-.647v27.4a109.4,109.4,0,0,1-15.1,1.3C508.307,186.7,491.912,170.523,491.912,142.91Z" transform="translate(188.734 -22.112)"/>
                                <path id="Path_43" data-name="Path 43" d="M570.845,132.234c0-34.514,26.318-61.478,59.969-61.478,14.022,0,25.887,4.529,33.867,12.51V27.829h32.357V191.123h-30.2V179.906c-8.413,9.06-21.355,13.806-35.808,13.806C596.516,193.712,570.845,166.533,570.845,132.234Zm93.836,0c0-18.982-12.295-32.357-29.768-32.357-17.9,0-30.633,13.589-30.633,32.357,0,18.982,12.728,32.357,30.633,32.357C652.387,164.591,664.681,151.433,664.681,132.234Z" transform="translate(232.014 -27.829)"/>
                                <path id="Path_44" data-name="Path 44" d="M673.294,118.94c0-35.161,25.669-61.262,59.322-61.262,37.318,0,58.242,25.454,58.242,58.026a42.259,42.259,0,0,1-1.51,12.512h-82.4c1.943,14.883,12.3,24.375,28.258,24.375,11.435,0,19.847-4.962,24.377-12.943l25.887,12.3c-7.12,17.258-26.534,28.69-50.693,28.69C697.453,180.634,673.294,153.023,673.294,118.94Zm34.3-14.452H756.56c-1.294-12.512-10.571-20.925-23.944-20.925C719.889,83.563,710.614,90.9,707.592,104.488Z" transform="translate(276.901 -14.751)"/>
                                <path id="Path_45" data-name="Path 45" d="M769.743,59.873h30.2V77.561c6.04-14.669,17.472-18.984,31.925-18.984h6.9V86.619H828.632c-15.746,0-26.532,10.139-26.532,27.612v63.419H769.743Z" transform="translate(319.16 -14.357)"/>
                                <g id="Group_33" data-name="Group 33" transform="translate(131.785 204.217)">
                                    <path id="Path_46" data-name="Path 46" d="M104.221,305.371,181.714,200.75H104.221V171.629h122.3v26.964L149.254,303.214H228.8v29.121H104.221Z" transform="translate(-104.221 -169.04)"/>
                                    <path id="Path_47" data-name="Path 47" d="M197.864,272.878v-71.4h32.357v68.812c0,13.59,8.845,22.434,21.571,22.434s21.572-8.843,21.572-22.434V201.477h32.357v117.78h-30.2V304.8c-6.042,11.648-18.121,17.041-33.005,17.041C213.826,321.844,197.864,302.215,197.864,272.878Z" transform="translate(-63.192 -155.962)"/>
                                    <path id="Path_48" data-name="Path 48" d="M289.469,169.829h32.357v88.01l39.907-42.5h39.909L358.93,262.8l42.5,70.324H364.755l-29.985-50.262L321.826,297.1v36.024H289.469Z" transform="translate(-23.055 -169.829)"/>
                                    <path id="Path_49" data-name="Path 49" d="M376.219,272.878v-71.4h32.357v68.812c0,13.59,8.845,22.434,21.571,22.434s21.572-8.843,21.572-22.434V201.477h32.357v117.78h-30.2V304.8c-6.04,11.648-18.121,17.041-33,17.041C392.182,321.844,376.219,302.215,376.219,272.878Z" transform="translate(14.954 -155.962)"/>
                                    <path id="Path_50" data-name="Path 50" d="M469.219,202.266h30.2v14.453c6.042-11.649,18.121-17.042,33.005-17.042,26.532,0,44.652,17.9,44.652,44.653v75.716H544.719V251.233c0-13.59-8.845-22.435-21.572-22.435s-21.571,8.845-21.571,22.435v68.812H469.219Z" transform="translate(55.702 -156.751)"/>
                                    <path id="Path_51" data-name="Path 51" d="M576.514,243.386H556.669V215.343h19.845v-1.726c0-27.828,17.042-43.789,46.379-43.789a62.389,62.389,0,0,1,11.433,1.079v27.611a45.572,45.572,0,0,0-7.119-.647c-12.08,0-18.336,5.393-18.336,15.746v1.726h25.24v28.042h-25.24v89.738H576.514Z" transform="translate(94.018 -169.829)"/>
                                    <path id="Path_52" data-name="Path 52" d="M642.363,284.91V237.669H622.517V209.626H623.6c12.08,0,18.768-6.04,18.768-18.119v-8.629H674.72v26.748h27.611v28.042H674.72v45.084c0,12.079,6.47,17.9,20.061,17.9a56.019,56.019,0,0,0,7.55-.646v27.4a109.412,109.412,0,0,1-15.1,1.294C658.757,328.7,642.363,312.521,642.363,284.91Z" transform="translate(122.869 -164.112)"/>
                                </g>
                                <path id="Path_4" data-name="Path 4" d="M12.586,37.415,82.6,107.423,12.586,177.43Z" transform="translate(-12.586 -23.629)"/>
                            </g>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="551.028" height="377.673" viewBox="0 0 551.028 377.673" className={"svg-logo"}>
                            <g id="Group_35" data-name="Group 35" transform="translate(1.161 1)">
                                <path className="svg-path svg-path-logo svg-path-dark" id="Path_53" data-name="Path 53" d="M893.333,397.827V338.257L992.582,236.04q29.291-30.229,44.424-47.9,15.146-17.568,20.839-29.522a53.753,53.753,0,0,0,5.715-23.3q0-20.353-13.166-32.026c-8.764-7.753-19.769-11.671-33-11.671a59.751,59.751,0,0,0-36.225,11.671c-10.582,7.795-18.357,19.588-23.324,35.5l-67-21.848a107.02,107.02,0,0,1,24.818-50.119,128.438,128.438,0,0,1,45.655-33A138.739,138.739,0,0,1,1017.4,22.154q36.712,0,64.517,12.884Q1109.692,48,1125.089,71.264q15.387,23.384,15.387,54.6a113.3,113.3,0,0,1-6.946,38.972q-6.966,19.083-21.1,38.69-14.115,19.629-36.469,42.445L991.108,333.33h152.84v64.5Z" transform="translate(-890.849 -22.154)"/>
                                <path className="svg-path svg-path-logo svg-path-dark" id="Path_54" data-name="Path 54" d="M1098.992,397.827V338.257l99.249-102.218q29.288-30.229,44.424-47.9,15.144-17.568,20.839-29.522a53.751,53.751,0,0,0,5.715-23.3q0-20.353-13.166-32.026c-8.764-7.753-19.769-11.671-33-11.671a59.752,59.752,0,0,0-36.226,11.671c-10.582,7.795-18.356,19.588-23.324,35.5l-67-21.848a107.032,107.032,0,0,1,24.817-50.119,128.456,128.456,0,0,1,45.657-33,138.734,138.734,0,0,1,56.077-11.672q36.711,0,64.516,12.884Q1315.351,48,1330.748,71.264q15.387,23.384,15.387,54.6a113.342,113.342,0,0,1-6.946,38.972q-6.966,19.083-21.1,38.69-14.115,19.629-36.468,42.445l-84.851,87.356h152.84v64.5Z" transform="translate(-800.74 -22.154)"/>
                            </g>
                        </svg>
                    </div>
                    <div className="landing__infobanner">
                        <h1>Das Klimakulturfestival</h1>
                        <h1 className={"mobile"}>7.-9. Oktober</h1>
                        <div className="btn__outer">
                            <a href={"#programm"} className="btn__inner btn__landing btn__arrowdown large text-center light color-dark border-dark bg-light">
                                Zum Programm
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.863" height="13.984" viewBox="0 0 11.863 13.984">
                                    <g id="Group_144" className={"svg-path-dark svg-path"} data-name="Group 144" transform="translate(-1067.314 -667.193)">
                                        <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(1926.027 2)"/>
                                        <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(1926.459 0.21)"/>
                                    </g>
                                </svg>

                            </a>
                        </div>
                        <h1 className={"desktop"}>7.-9. Oktober</h1>
                    </div>
                    <div className="landing__image">
                        <StaticImage src="../images/keyvisual.jpg" alt={"Markt der Zukunft - Keyvisual"} placeholder={"dominantColor"}></StaticImage>
                    </div>
                </div>
            </div>
            <div className="intro__outer" id={"about"}>
                <div className="intro__inner">
                    <div className="intro__text__container">
                        <p className={"large intro__text"}>
                            Der Markt der Zukunft macht Klimakultur gemeinsam mit Akteur:innen aus Initiativkultur,
                            Wissenschaft, Wirtschaft, Zivilgesellschaft und Kunst als vielschichtigen und spannenden
                            Aktionsraum erlebbar.
                        </p>
                    </div>
                    <div className="intro__collapsed" ref={contentRef}
                         style={open ? {height: contentRef.current.scrollHeight + "px"} : {height: "0px"}}>
                        <p className={"intro__questions"}>
                            Welche konkreten Möglichkeiten gibt es, der Klima- und Biodiversitätskrise auf der Basis
                            solidarischen Handelns entgegenzutreten?
                            <br/>
                            Welche transformativen Wirtschafts-, Arbeits-, Bildungs- und Sozialmodelle zeichnen sich ab?
                            <br/>
                            Wie können wir Ernährung und Landwirtschaft neu denken, damit ein gutes Leben für alle auch
                            in Zukunft möglich bleibt?
                        </p>
                        <div className="intro__collapsed__sub__container">
                            <div className="intro__collapsed__sub first">
                                <h2>
                                    Das Forum der Initiativen
                                </h2>
                                <p>
                                    In der Aula der Alten Universität Graz tauschen sich am 8. Oktober (13 bis 17 Uhr)
                                    Vertreter:innen von mehr als 60 Initiativen aus den unterschiedlichsten Bereichen
                                    mit
                                    Expert:innen, Künstler:innen und interessiertem Publikum in besonderen Denk- und
                                    Handlungsräumen aus, die von Daniela Brasil, Olia Fedorova, ILA, Ivana Marjanović
                                    und
                                    Nina Vobruba
                                    künstlerisch gestaltet werden.
                                </p>
                            </div>
                            <div className="intro__collapsed__sub second">
                                <h2>
                                    Reparatur der Zukunft, Gutes Klima für Graz
                                </h2>
                                <p>
                                    Fünf österreichische Projekte werden im Rahmen der Ö1-Initiative <strong>Reparatur
                                    der
                                    Zukunft</strong> zur Teilnahme ausgewählt und von Mentor:innen aus dem Netzwerk der
                                    Gründungsgarage, der TU Graz und des Markt der Zukunft gecoacht. Fünf internationale
                                    Projekte werden zum Festival eingeladen und erhalten den neu geschaffenen <strong>Klimainnovationspreis
                                    der Österreichischen Auslandskultur</strong> für internationale
                                    Projekte/Gestalter:innen
                                    und ein Preisgeld in Höhe von á EUR 2000,-. Außerdem wird der <strong>Umweltpreis
                                    der
                                    Stadt Graz 2022</strong> für Projekte von Kindern und Jugendlichen vergeben.
                                    Zentrale
                                    Themen des Festivals werden in der September-Ausgabe des <strong>Megaphon –
                                    Straßenmagazin und soziale
                                    Initiative</strong> diskutiert, das mit Journalismus-Studierenden der FH JOANNEUM
                                    produziert wird.
                                </p>
                            </div>
                            <div className="intro__collapsed__sub third">
                                <h2>
                                    Eine Plattform für vielfältiges Wissen
                                </h2>
                                <p>
                                    Der Markt der Zukunft nimmt langfristig eine Drehscheibenfunktion für Akteur:innen
                                    des
                                    nachhaltigen Wandels ein und entwickelt einen Aktions- und Kommunikationsraum für
                                    den
                                    Austausch von Erfahrungen, Ideen, Konzepten und Praktiken.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="btn__outer flex right">
                        <div className="btn__inner btn__arrowdown text-center light color-dark border-dark bg-light btn__intro" onClick={toggle}>
                            {open ? "Weniger Info" : "Mehr Info"}
                            <svg xmlns="http://www.w3.org/2000/svg" style={open ? {transform: "rotate(180deg) scale(var(--scale))"} : {transform: "rotate(0) scale(var(--scale))"}} width="11.863" height="13.984" viewBox="0 0 11.863 13.984">
                                <g id="Group_144" className={"svg-path-dark svg-path"} data-name="Group 144" transform="translate(-1067.314 -667.193)">
                                    <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(1926.027 2)"/>
                                    <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(1926.459 0.21)"/>
                                </g>
                            </svg>
                        </div>

                    </div>

                </div>
            </div>
            <div className="programm__outer">
                <div className="programm__inner" id={"programm"}>
                    <h3>Programm</h3>
                    <div className="programm__row">
                        <div className="left">
                            <h1>Freitag, 7. Oktober</h1>
                        </div>
                        <div className="right">
                            <h1 className="semibold">
                                <span className="time">17:00 </span><span className="outline-desktop">Eröffnung</span>
                            </h1>
                            <p>
                                Präsentation „Manual Klimakultur“ & GET-TOGETHER der teilnehmenden Initiativen
                                Kooperationspartner:innen, Moderation: Wolfgang Schlag (Initiator, Markt der
                                Zukunft), Thomas Wolkinger (Autor, „Manual Klimakultur“)
                            </p>
                        </div>
                    </div>
                    <div className="programm__row">
                        <div className="left">
                            <h1>Samstag, 8. Oktober</h1>
                        </div>
                        <div className="right">
                            <h1 className="semibold">13:00 - 17:00</h1>
                            <p>
                                MARKT DER ZUKUNFT: VERNETZUNGSBAR & RAUM DER ENERGIE
                                (Foyer) mit Künstler:innen, Gestalter:innen und Expert:innen
                            </p>
                            <h1 className="semibold">
                                <span className="time">14:00 </span><span className="outline-desktop">Panel Wildnis</span>
                            </h1>
                            <p>
                                Panel „Wildnis“ mit Kunstraum Innsbruck (Ivana Marjanović),
                                Ökoteam – Institut für Tierökologie und Naturraumplanung (Thomas Frieß), Nationalpark
                                Gesäuse (Martin Hartmann), Wolf Science Center Ernstbrunn (Marianne Heberlein)
                            </p>
                            <h1 className="semibold">
                                <span className="time">15:00 </span><span className="outline-desktop">Panel Energie</span>
                            </h1>
                            <p>
                                Panel „Energie“ mit agentur scan – Raum/Markt/Gesellschaft
                                (Rainer Rosegger), Energieagentur Weststeiermark (Irmtraud Pribas), LIFE Institut,
                                JOANNEUM RESEARCH (Franz Prettenthaler), Science Park Graz (Martin Mössler)
                            </p>
                            <h1 className="semibold">
                                <span className="time">16:00 </span><span className="outline-desktop">Diskussionsrunde</span>
                            </h1>
                            <p>
                                Diskussionsrunde „Gesellschaft, Forschung und Entwicklung im Zeichen der
                                Transformation“ mit Harald Kainz (Rektor, Technische Universität Graz), Lars Peter
                                Kamolz
                                (Univ.-Prof., Dr. med., Univ. Klinik für Chirurgie Graz), Heinz Mayer (Geschäftsführer,
                                JOANNEUM RESEARCH), Caroline Schober (Vizerektorin, Medizinische Universität Graz),
                                Andrea K. Steiner (Direktorin, Wegener Center für Klima und Globalen Wandel,
                                Karl-Franzens-Universität Graz), Moderation: Marlene Nowotny (Ö1 Wissenschaftsredaktion)
                            </p>
                            <h1 className="semibold">
                                <span className="time">17:00 </span><span className="outline-desktop">Preisverleihung</span>
                            </h1>
                            <p>
                                Verleihung des Klimainnovationspreis der Österreichischen Auslandskultur für
                                internationale Projekte/Gestalter:innen & Umweltpreis der Stadt Graz 2022 an Projekte
                                von Kindern und Jugendlichen, Moderation: Marlene Nowotny (Ö1 Wissenschaftsredaktion),
                                in Kooperation mit Ö1 Reparatur der Zukunft, Umweltamt der Stadt Graz
                            </p>
                            <h1 className="semibold">
                                <span className="time">17:30 </span><span className="outline-desktop">Tanz der Teufel</span>
                            </h1>
                            <p>
                                Eine Performance zwischen Spoken Poetry und Musik
                                mit Patrick Dunst (Saxophon), Fiston Mwanza Mujila (Spoken Poetry),
                                in Kooperation mit Afro-Asiatisches Institut Graz

                            </p>
                        </div>
                    </div>
                    <div className="programm__row">
                        <div className="left">
                            <h1>Sonntag, 9. Oktober</h1>
                        </div>
                        <div className="right">
                            <h1 className="semibold">
                                <span className="time">09:00 </span><span className="outline-desktop">Coaching</span>
                            </h1>
                            <p>
                                Coaching ausgezeichneter Ö1 Reparatur der Zukunft-Projekte durch Mentor:innen aus dem
                                Netzwerk der Gründungsgarage, der TU Graz und des Markt der Zukunft,
                                in Kooperation mit TU Graz
                            </p>
                            <p><strong>Künstler:innen:</strong> Daniela Brasil, Olia Fedorova, ILA, Ivana Marjanovic,
                                Nina Vobruba</p>
                        </div>
                    </div>
                </div>
                <Marquee speed={100} gradient={false}>
                    <div className={"marqueeText"}>
                        <h1 className="marqueeText__inner">Alte Universität Graz, Aula <div className="marquee__image">
                            <StaticImage src={"../images/location.jpg"} alt={"Alte Universität Graz, Aula"}></StaticImage>
                        </div></h1>
                        <h1 className="marqueeText__inner">Alte Universität Graz, Aula <div className="marquee__image">
                            <StaticImage src={"../images/location.jpg"} alt={"Alte Universität Graz, Aula"}></StaticImage>
                        </div></h1>
                        <h1 className="marqueeText__inner">Alte Universität Graz, Aula <div className="marquee__image">
                            <StaticImage src={"../images/location.jpg"} alt={"Alte Universität Graz, Aula"}></StaticImage>
                        </div></h1>
                        <h1 className="marqueeText__inner">Alte Universität Graz, Aula <div className="marquee__image">
                            <StaticImage src={"../images/location.jpg"} alt={"Alte Universität Graz, Aula"}></StaticImage>
                        </div></h1>
                        <h1 className="marqueeText__inner">Alte Universität Graz, Aula <div className="marquee__image">
                            <StaticImage src={"../images/location.jpg"} alt={"Alte Universität Graz, Aula"}></StaticImage>
                        </div></h1>
                    </div>
                </Marquee>
                <div className="base-padding">
                    <div className="btn__outer flex right">
                        <a href={"https://goo.gl/maps/n5C6LoDeGL2MzpJi9"} target={"_blank"} className="btn__inner btn__landing btn__arrowdown large text-center light color-dark border-dark bg-light">
                            Bring mich dorthin
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.694" height="20.932" viewBox="0 0 15.694 20.932">
                                <g className="svg-path svg-path-dark">
                                    <path id="Path_86" data-name="Path 86" d="M2877.2,1135.355h.738c.128.014.255.026.383.042a6.861,6.861,0,0,1,5.947,8.147,12.78,12.78,0,0,1-1.355,3.575,36.964,36.964,0,0,1-4.781,6.838.644.644,0,0,1-1.124,0,38.862,38.862,0,0,1-4.3-5.964,17.033,17.033,0,0,1-1.692-3.839,6.614,6.614,0,0,1,.257-4.6,6.721,6.721,0,0,1,5.369-4.121C2876.824,1135.4,2877.014,1135.379,2877.2,1135.355Z" transform="translate(-2869.718 -1134.355)"/>
                                    <path id="Path_87" data-name="Path 87" d="M2877.271,1144.516a2.611,2.611,0,1,0-2.6-2.607A2.614,2.614,0,0,0,2877.271,1144.516Z" transform="translate(-2869.417 -1134.051)"/>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            <div className="landing__newsletter__outer">
                <h3>Newsletter</h3>
                <div className="landing__newsletter__inner">

                    <p className={"short"}>
                        Bleib am aktuellen Stand was Nachhaltigkeit, den Markt der Zukunft und die Initiativen dahinter
                        betrifft.
                    </p>
                    <div className="newsletter__form">
                        <LandingNewsletter></LandingNewsletter>
                    </div>
                </div>
            </div>
            <div className="landing__kontakt__outer">
                <div className="landing__konktakt__inner">
                    <h1>Fragen?</h1>
                    <div className="flex vcenter">
                        <h1 className={"semibold"}>Dann schreib uns eine E-Mail.</h1>
                        <div className="btn__outer">
                            <Link to={"/kontakt"} className="btn__inner btn__arrowdown btn__landing__kontakt btn__mail text-center light color-dark border-dark bg-light">
                                Kontakt
                                <svg xmlns="http://www.w3.org/2000/svg" width="23.437" height="17.06" viewBox="0 0 23.437 17.06">
                                    <g className="svg-path svg-path-dark" id="Group_141" data-name="Group 141" transform="translate(-2801.586 -1193.838)">
                                        <path id="Path_233" data-name="Path 233" d="M2803.283,1196.268l8.42,8.421a2.122,2.122,0,0,0,3,0l8.521-8.521m-20.218,14.02,7.1-7.1m13.493,7.1-7.2-7.2m5.8,7.906h-17.8a1.818,1.818,0,0,1-1.818-1.818v-11.426a1.817,1.817,0,0,1,1.818-1.817h17.8a1.817,1.817,0,0,1,1.817,1.817v11.426A1.817,1.817,0,0,1,2822.206,1210.9Z" transform="translate(0 -1)"/>
                                    </g>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="partner__outer">
                <div className="partner__inner">
                    <h3>Partner</h3>
                </div>

                <SponsorMarquee></SponsorMarquee>
            </div>
        </Layout>
    )
}

export default IndexPage


/*
<Marquee speed={100} gradient={false}>
                    <div className={"marqueeText"}>
                        <h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
                        <h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
                        <h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
                        <h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
                        <h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
                    </div>
                </Marquee>
 */
